<template>
    <div class="content-add-customer global-content-detail-all">
        <div class="global-content-detail" >
            <div id="iHeaderDetail" ref="iHeaderDetail">
                <header-detail-component-global sTextDetail="Creación de un cliente" sBackHref="Customer" />
                <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
            </div>
            <v-container fluid>
                <!--#region SECTION EMPRESA -->

                <!-- #region INFORMACION DE IDENTIFICACION  -->
                <div class="content-primary-text-title-global">
                    <p class="primary-text-title-global">
                        Información de identificación
                    </p>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field v-model="oCustomer.sPublicName" label="Nombre público de la empresa"
                                    placeholder="Nombre público de la empresa..." class="global-text-field"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="50" @keyup="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Nombre público de la empresa(Alias)
                                            <span class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field v-model="oCustomer.sEmail" label="Correo electrónico de la empresa"
                                    placeholder="Correo electrónico de la empresa..." class="global-text-field"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="70" @keyup="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Correo electrónico de la empresa
                                            <span class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <phone-component-global :bImportantDate="true"
                                    @updateCountryCallingCode="updateCountryCallingCode"
                                    :sCountryCallingCodeOrigin="oCustomer.sCountryCallingCode"
                                    @updateAreaCallingCode="updateAreaCallingCode"
                                    :sAreaCallingCodeOrigin="oCustomer.sAreaCallingCode"
                                    @updatePhoneNumber="updatePhoneNumber" :sPhoneNumberOrigin="oCustomer.sPhoneNumber"
                                    @updatePhoneExtension="updatePhoneExtension"
                                    :sPhoneExtensionOrigin="oCustomer.sPhoneExtension" />
                            </v-col>


                            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                <v-select v-model="sCountry" :items="aItemsCountry" item-text="sName"
                                    item-value="sCountryId" label="País" class="global-select"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" outlined
                                    @change="validateFormCustomer()">
                                    <template slot="label">
                                        <span>País de la empresa<span class="important-data">*</span></span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                <v-select v-model="oCustomer.sLocationStateId" :disabled="bDisabledState"
                                    :items="aItemsStates" item-text="sName" item-value="sStateId" label="Estado"
                                    class="global-select" color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" outlined
                                    @change="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Estado de la empresa<span class="important-data">*</span></span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                <v-text-field v-model="oCustomer.sLocationCityName"
                                    :disabled="!(oCustomer.sLocationStateId !== null)" label="Ciudad"
                                    placeholder="Ciudad..." class="global-text-field"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="40" @keyup="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Ciudad de la empresa<span class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                <v-text-field v-model="oCustomer.sLocationZIPCode" :disabled="
                                    !(
                                        oCustomer.sLocationCityName.trim() !== '' &&
                                        oCustomer.sLocationStateId !== null
                                    )
                                " label="Código postal de la empresa" placeholder="Código postal..."
                                    class="global-text-field" color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="5" @keyup="validateFormCustomer()" @keypress="fieldNumber($event)"
                                    @paste="noLetterZipCodeInformation()">
                                    <template slot="label">
                                        <span>Código postal de la empresa<span class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field v-model="oCustomer.sLocationAddress" label="Dirección de la empresa"
                                    placeholder="Ej: Privada San Pedro..." class="global-text-field"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="50" @keyup="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Dirección de la empresa<span class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field v-model="oCustomer.sOptionalLocationAddress"
                                    label="Especificación de dirección"
                                    placeholder="Ej: Piso 4, Loft 1, casa color amarillo..." class="global-text-field"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="70" @keyup="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Especificación de dirección<span class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-divider class="divider-global mt-9"></v-divider>
                </div>
                <!-- #endregion INFORMACION DE IDENTIFICACION  -->

                <!-- #region INFORMACION DE ENCARGADO  -->
                <div class="content-primary-text-title-global">
                    <p class="primary-text-title-global">
                        Información de encargado
                    </p>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field v-model="oCustomer.sManagerFullName"
                                    label="Nombre del encargado de la empresa"
                                    placeholder="Nombre del encargado de la empresa..." class="global-text-field"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="50" @keyup="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Nombre del encargado de la empresa<span
                                                class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field v-model="oCustomer.sManagerEmail" label="Correo electrónico del encargado"
                                    placeholder="Correo electrónico del encargado..." class="global-text-field"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="70" @keyup="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Correo electrónico del encargado<span
                                                class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                <phone-component-global :bImportantDate="true"
                                    @updateCountryCallingCode="updateManagerCountryCallingCode"
                                    :sCountryCallingCodeOrigin="
                                        oCustomer.sManagerCountryCallingCode
                                    " @updateAreaCallingCode="updateManagerAreaCallingCode"
                                    :sAreaCallingCodeOrigin="oCustomer.sManagerAreaCallingCode"
                                    @updatePhoneNumber="updateManagerPhoneNumber"
                                    :sPhoneNumberOrigin="oCustomer.sManagerPhoneNumber"
                                    @updatePhoneExtension="updateManagerPhoneExtension"
                                    :sPhoneExtensionOrigin="oCustomer.sManagerPhoneExtension" />
                            </v-col>

                        </v-row>
                    </v-container>
                    <v-divider class="divider-global mt-9 mb-9"></v-divider>
                </div>
                <!-- #endregion INFORMACION DE ENCARGADO  -->

                <!-- #region INFORMACION FISCAL  -->
                <div class="content-primary-text-title-global">
                    <p class="primary-text-title-global">
                        Información fiscal
                    </p>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                <v-text-field v-model="oCustomer.sBusinessName" label="Nombre legal de la empresa"
                                    placeholder="Nombre legal de la empresa..." class="global-text-field"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="50" @keyup="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Nombre legal de la empresa<span class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                <v-text-field v-model="oCustomer.sTaxEmail" label="Correo electrónico de facturación"
                                    placeholder="Correo electrónico de facturación..." class="global-text-field"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="70" @keyup="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Correo electrónico de facturación<span
                                                class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                                <v-text-field v-model="oCustomer.sTaxId" label="Registro federal de contribuyentes(RFC)"
                                    placeholder="Registro federal de contribuyentes(RFC)..." class="global-text-field"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="13" @keyup="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Registro federal de contribuyentes(RFC)<span
                                                class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                <v-select v-model="sCountryTax" :items="aItemsCountry" item-text="sName"
                                    item-value="sCountryId" label="País (facturación)" class="global-select"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" outlined
                                    @change="validateFormCustomer()">
                                    <template slot="label">
                                        <span>País (facturación)<span class="important-data">*</span></span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                <v-select v-model="oCustomer.sTaxLocationStateId" :disabled="bDisabledStateTax"
                                    :items="aItemsStatesTax" item-text="sName" item-value="sStateId"
                                    label="Estado (facturación)" class="global-select"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" outlined
                                    @change="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Estado (facturación)<span class="important-data">*</span></span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                <v-text-field v-model="oCustomer.sTaxLocationCityName"
                                    :disabled="!(oCustomer.sTaxLocationStateId !== null)" label="Ciudad (facturación)"
                                    placeholder="Ciudad (facturación)..." class="global-text-field"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    @keyup="validateFormCustomer()" maxlength="40">
                                    <template slot="label">
                                        <span>Ciudad (facturación)<span class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                                <v-text-field v-model="oCustomer.sTaxLocationZIPCode" :disabled="
                                    !(
                                        oCustomer.sTaxLocationCityName.trim() !== '' &&
                                        oCustomer.sTaxLocationStateId !== null
                                    )
                                " label="Código postal(facturación)" placeholder="Código postal (facturación)..."
                                    class="global-text-field" color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="5" @keyup="validateFormCustomer()" @keypress="fieldNumber($event)"
                                    @paste="noLetterZipCodeTax()">
                                    <template slot="label">
                                        <span>Código postal (facturación)<span class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>


                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field v-model="oCustomer.sTaxLocationAddress"
                                    label="Dirección de facturación de la empresa"
                                    placeholder="Ej: Privada San Pedro..." class="global-text-field"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="255" @keyup="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Dirección de facturación de la empresa<span
                                                class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field v-model="oCustomer.sOptionalTaxLocationAddress"
                                    label="Especificación de dirección de facturación"
                                    placeholder="Ej: Piso 4, Loft 1, casa color amarillo..." class="global-text-field"
                                    color="var(--primary-color-border-input)"
                                    background-color="var(--primary-color-menu)" persistent-placeholder outlined
                                    maxlength="255" @keyup="validateFormCustomer()">
                                    <template slot="label">
                                        <span>Especificación de dirección de facturación<span
                                                class="important-data">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-col>


                        </v-row>
                    </v-container>
                    <v-divider class="divider-global mt-9 mb-9"></v-divider>
                </div>
                <!-- #endregion INFORMACION FISCAL  -->




                <!--#endregion SECTION EMPRESA -->

                <!--#region SECTION BUTTONS -->

                <div class="content-btns-accions">
                    <div class="content-btn-second">
                        <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
                    </div>
                    <!-- <v-spacer></v-spacer> -->
                    <div class="content-btn-primary">
                        <v-btn class="global-btn-primary" :loading="bLoading" :disabled="!bAddCustomer" @click="add()">
                            Añadir
                        </v-btn>
                    </div>
                </div>
                <!--#endregion SECTION BUTTONS -->
            </v-container>
        </div>
        <!-- <footer-component-global /> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            screenWidth: 0,
            screenHeight: 0,
            heightMax: 0,
            bAddCustomer: false,
            bLoading: false,
            aItemsCountryPhone: [
                { value: "52", text: "+52", icono: "" },
                { value: "52", text: "+52", icono: "" },
            ],
            sCountry: null,
            sCountryTax: null,
            aItemsCountry: [],
            aItemsStates: [],
            aItemsStatesTax: [],
            bDisabledState: true,
            bDisabledStateTax: true,
            oCustomer: {
                //Datos informativos
                sPublicName: "",
                sEmail: "",
                sCountryCallingCode: "52",
                sAreaCallingCode: "",
                sPhoneNumber: "",
                sPhoneExtension: "",
                sLocationStateId: null,
                sLocationCityName: "",
                sLocationZIPCode: "",
                sLocationAddress: "",
                sOptionalLocationAddress: "",

                ///Responsable de la empresa
                sManagerFullName: "",
                sManagerEmail: "",
                sManagerCountryCallingCode: "52",
                sManagerAreaCallingCode: "",
                sManagerPhoneNumber: "",
                sManagerPhoneExtension: "",



                //Informacion de faturacion
                sBusinessName: "",
                sTaxEmail: "",
                sTaxId: "",
                sTaxLocationStateId: null,
                sTaxLocationCityName: "",
                sTaxLocationZIPCode: "",
                sTaxLocationAddress: "",
                sOptionalTaxLocationAddress: "",

            },
            itemsBreadcrumbs: [
                {
                    text: "Clientes",
                    disabled: false,
                    to: { name: "Customer" },
                },
                {
                    text: "Creación de un cliente",
                    disabled: true,
                    to: { name: "" },
                },
            ],
        };
    },
    beforeMount() {
        this.$store.commit("setsNameDetail", "")
        this.getCountires();
    },
    updated() {
        this.handleResize();
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        //#region RESIZE PAGE
        matchHeight() {
            this.$nextTick((e) => {
                if (this.$refs.iHeaderDetail !== undefined) {
                    this.heightMax = this.$refs.iHeaderDetail.clientHeight;
                    this.heightMax = this.heightMax + 123; //123 IS NAVBAR
                }
                if (window.innerWidth > 600) {
                    this.screenHeight = window.innerHeight - 100;
                } else {
                    this.screenHeight = window.innerHeight - 100;
                }
            });
        },
        handleResize: function () {
            this.matchHeight();
        },
        //#endregion RESIZE PAGE
        fieldNumber(evt) {
            if (
                (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
                evt.which > 57
            ) {
                evt.preventDefault();
            }
        },
        noLetterZipCodeInformation() {
            setTimeout(() => {
                const regexExp = /^([0-9])+$/;
                let result = regexExp.exec(this.oCustomer.sLocationZIPCode);
                if (result == null) {
                    this.oCustomer.sLocationZIPCode = "";
                } else {
                    this.oCustomer.sLocationZIPCode =
                        this.oCustomer.sLocationZIPCode;
                }
            }, 100);
        },
        noLetterZipCodeTax() {
            setTimeout(() => {
                const regexExp = /^([0-9])+$/;
                let result = regexExp.exec(this.oCustomer.sTaxLocationZIPCode);
                if (result == null) {
                    this.oCustomer.sTaxLocationZIPCode = "";
                } else {
                    this.oCustomer.sTaxLocationZIPCode =
                        this.oCustomer.sTaxLocationZIPCode;
                }
            }, 100);
        },
        emptyField() {
            this.sCountry = null;
            this.sCountryTax = null;
            this.bLoading = false;
            //Datos informativos
            this.oCustomer.sPublicName = "";
            this.oCustomer.sEmail = "";
            this.oCustomer.sCountryCallingCode = "52";
            this.oCustomer.sAreaCallingCode = "";
            this.oCustomer.sPhoneNumber = "";
            this.oCustomer.sPhoneExtension = "";
            this.oCustomer.sLocationStateId = null;
            this.oCustomer.sLocationCityName = "";
            this.oCustomer.sLocationZIPCode = "";
            this.oCustomer.sLocationAddress = "";
            this.oCustomer.sOptionalLocationAddress = "";

            ///Responsable de la empresa
            this.oCustomer.sManagerFullName = "";
            this.oCustomer.sManagerEmail = "";
            this.oCustomer.sManagerCountryCallingCode = "52";
            this.oCustomer.sManagerAreaCallingCode = "";
            this.oCustomer.sManagerPhoneNumber = "";
            this.oCustomer.sManagerPhoneExtension = "";

            //Informacion de faturacion
            this.oCustomer.sBusinessName = "";
            this.oCustomer.sTaxEmail = "";
            this.oCustomer.sTaxId = "";
            this.oCustomer.sTaxLocationStateId = null;
            this.oCustomer.sTaxLocationCityName = "";
            this.oCustomer.sTaxLocationZIPCode = "";
            this.oCustomer.sTaxLocationAddress = "";
            this.oCustomer.sOptionalTaxLocationAddress = "";

        },
        close() {
            this.$router.push({
                name: "Customer",
                params: {},
            });
            this.bLoading = true;

            this.emptyField();
        },
        getCountires() {
            DB.get(`${URI}/api/sp/v1/locations/countries`, {
                params: {},
                headers: {
                    Authorization: "Bearer " + this.$store.state.sToken,
                },
            })
                .then((response) => {
                    this.aItemsCountry = response.data.results;
                    this.aItemsCountry.unshift({
                        sCountryId: null,
                        sName: "Seleccione un país",
                        sCode: null,
                    });

                    this.aItemsStates.unshift({
                        sStateId: null,
                        sCountryId: null,
                        sName: "Seleccione un estado",
                        sCode: null,
                    });
                    this.aItemsStatesTax.unshift({
                        sStateId: null,
                        sCountryId: null,
                        sName: "Seleccione un estado",
                        sCode: null,
                    });
                })
                .catch((error) => {
                    this.Error(error.response.data);
                });
        },
        getState(id) {
            DB.get(`${URI}/api/sp/v1/locations/countries/${id}/states`, {
                params: {},
                headers: {
                    Authorization: "Bearer " + this.$store.state.sToken,
                },
            })
                .then((response) => {
                    this.oCustomer.sLocationStateId = null;
                    this.aItemsStates = response.data.results;
                    this.aItemsStates.unshift({
                        sStateId: null,
                        sCountryId: null,
                        sName: "Seleccione un estado",
                        sCode: null,
                    });
                    this.validateFormCustomer();
                    this.bDisabledState = false;
                })
                .catch((error) => {
                    this.Error(error.response.data);
                });
        },
        getStateTax(id) {
            DB.get(`${URI}/api/sp/v1/locations/countries/${id}/states`, {
                params: {},
                headers: {
                    Authorization: "Bearer " + this.$store.state.sToken,
                },
            })
                .then((response) => {
                    this.oCustomer.sTaxLocationStateId = null;
                    this.aItemsStatesTax = response.data.results;
                    this.aItemsStatesTax.unshift({
                        sStateId: null,
                        sCountryId: null,
                        sName: "Seleccione un estado",
                        sCode: null,
                    });
                    this.validateFormCustomer();
                    this.bDisabledStateTax = false;
                })
                .catch((error) => {
                    this.Error(error.response.data);
                });
        },

        addCustomer() {
            const payload = this.oCustomer,
                config = {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.sToken,
                        contentType: "application/x-www-form-urlencoded;charset=utf-8",
                    },
                };
            DB.post(`${URI}/api/sp/v1/customers`, payload, config)
                .then((response) => {
                    this.Success(response.data.message);
                    setTimeout(() => {
                        this.$store.commit("refresher", true);
                        this.$router
                            .push({
                                name: "Customer",
                                params: {},
                            })
                            .catch(() => { });
                    }, 2000);
                })
                .catch((error) => {
                    this.bLoading = false;
                    this.Error(error.response.data);
                });
        },
        add() {
            this.bLoading = true;
            this.$store
                .dispatch("getPermissionsByUserGlobal")
                .then((resp) => {
                    if (this.bAdminCustomer) {
                        this.addCustomer();
                    } else {
                        this.Error(this.$store.state.oError403);
                        this.bLoading = false;
                        this.$router
                            .push({
                                name: "Provider",
                                params: {},
                            })
                            .catch(() => { });
                    }
                })
                .catch((err) => {
                    this.Error(err);
                });
        },

        //UPDATE DATE PHONE ENTERPISE (ENTERPISE SECTION)
        updateCountryCallingCode(val) {
            this.oCustomer.sCountryCallingCode = val;
            this.validateFormCustomer();
        },
        updateAreaCallingCode(val) {
            this.oCustomer.sAreaCallingCode = val;
            this.validateFormCustomer();
        },
        updatePhoneNumber(val) {
            this.oCustomer.sPhoneNumber = val;
            this.validateFormCustomer();
        },
        updatePhoneExtension(val) {
            this.oCustomer.sPhoneExtension = val;
            this.validateFormCustomer();
        },

        //UPDATE DATE PHONE MANAGER (ENTERPISE SECTION)
        updateManagerCountryCallingCode(val) {
            this.oCustomer.sManagerCountryCallingCode = val;
            this.validateFormCustomer();
        },
        updateManagerAreaCallingCode(val) {
            this.oCustomer.sManagerAreaCallingCode = val;
            this.validateFormCustomer();
        },
        updateManagerPhoneNumber(val) {
            this.oCustomer.sManagerPhoneNumber = val;
            this.validateFormCustomer();
        },
        updateManagerPhoneExtension(val) {
            this.oCustomer.sManagerPhoneExtension = val;
            this.validateFormCustomer();
        },


        validateFormCustomer() {
            this.bAddCustomer =
                this.oCustomer.sPublicName.trim() !== "" &&
                this.oCustomer.sEmail.trim() !== "" &&
                this.oCustomer.sCountryCallingCode !== null &&
                this.oCustomer.sAreaCallingCode.trim() !== "" &&
                this.oCustomer.sPhoneNumber.trim() !== "" &&
                this.oCustomer.sCountry !== null &&
                this.oCustomer.sLocationStateId !== null &&
                this.oCustomer.sLocationCityName.trim() !== "" &&
                this.oCustomer.sLocationZIPCode.trim() !== "" &&
                this.oCustomer.sLocationAddress.trim() !== "" &&
                this.oCustomer.sManagerFullName.trim() !== "" &&
                this.oCustomer.sManagerEmail.trim() !== "" &&
                this.oCustomer.sManagerCountryCallingCode !== null &&
                this.oCustomer.sManagerAreaCallingCode.trim() !== "" &&
                this.oCustomer.sManagerPhoneNumber.trim() !== "" &&
                this.oCustomer.sBusinessName.trim() !== "" &&
                this.oCustomer.sTaxEmail.trim() !== "" &&
                this.oCustomer.sTaxId.trim() !== "" &&
                this.oCustomer.sCountryTax !== null &&
                this.oCustomer.sTaxLocationStateId !== null &&
                this.oCustomer.sTaxLocationCityName.trim() !== "" &&
                this.oCustomer.sTaxLocationZIPCode.trim() !== "" &&
                this.oCustomer.sTaxLocationAddress.trim() !== "";
        },
    },
    computed: {
        bShowCustomer() {
            return this.$store.state.bShowCustomer;
        },
        bAdminCustomer() {
            return this.$store.state.bAdminCustomer;
        },
    },
    watch: {
        sCountry() {
            if (this.sCountry !== null) {
                this.getState(this.sCountry);
            } else {
                this.bDisabledState = true;
                this.oCustomer.sLocationStateId = null;
                this.aItemsStates = [];
                this.aItemsStates.unshift({
                    sStateId: null,
                    sCountryId: null,
                    sName: "Seleccione un estado",
                    sCode: null,
                });
                this.validateFormCustomer();
            }
        },
        sCountryTax() {
            if (this.sCountryTax !== null) {
                this.getStateTax(this.sCountryTax);
            } else {
                this.bDisabledStateTax = true;
                this.oCustomer.sTaxLocationStateId = null;
                this.aItemsStatesTax = [];
                this.aItemsStatesTax.unshift({
                    sStateId: null,
                    sCountryId: null,
                    sName: "Seleccione un estado",
                    sCode: null,
                });
                this.validateFormCustomer();
            }
        },
    },
};
</script>
<style scoped>
.content-title-add-provider {
    width: 100%;
}

.title-add-provider {
    color: var(--primary-color-text) !important;
    font-family: "pop-SemiBold";
    font-size: 25px;
    text-align: center;
}

.content-carousel-1 {
    position: relative;
    /* height: 170px; */
    width: 100%;
    /* border: var(--primary-color-menu) dashed; */
    border-radius: 20px;
    justify-content: center;
    display: flex;
}

.content-carousel-2 {
    position: relative;
    /* height: 170px; */
    width: 100%;
    /* border: var(--primary-color-menu) dashed; */
    border-radius: 20px;
    justify-content: center;
    display: flex;
}

.content-icon-carousel {
    z-index: 9;
    position: absolute;
    display: flex;
    bottom: 10px;
    /* background-color: var(--primary-color-text-yellow); */
}

.content-icon-1 {
    cursor: pointer;
    border-radius: 100%;
    height: 10px;
    width: 10px;
    background-color: white;
}

.content-icon-2 {
    cursor: pointer;
    border-radius: 100%;
    height: 10px;
    width: 10px;
    margin-left: 10px;
    background-color: white;
}

.text-img-add {
    color: var(--primary-color-text) !important;
    font-family: "pop-Semibold";
    font-size: 9px;
    text-transform: initial;
}

.content-image {
    width: 100%;
    height: 180px !important;
    position: relative;
    border: 1px solid #70707080;
    border-radius: 20px;
}

.content-image img {
    width: 100%;
    height: auto;
}

@supports (object-fit: cover) {
    .content-image img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: 20px;
    }
}

.button-delete-item {
    position: absolute;
    right: 5px;
    top: 5px;
    min-width: 40px !important;
    height: 40px !important;
    background-color: var(--primary-color-btn-neutral-red) !important;
    padding: 0px !important;
    border-radius: 10px;
}

.button-add-img {
    width: 100% !important;
    height: 180px !important;
    background: transparent 0% 0% no-repeat padding-box !important;
    border: 2px dashed var(--primary-color-divider);
    border-radius: 20px;
    opacity: 1;
}

.content-arrows-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.text-identification {
    margin-bottom: 0px;
    color: var(--primary-color-text);
}

.content-text-informacion-fiscal {
    letter-spacing: 4.8px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    font-family: "pop-Bold";
    color: var(--primary-color-color-title-information);
}

.content-text-informacion-manager {
    letter-spacing: 4.8px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    font-family: "pop-Regular";
    color: var(--primary-color-color-title-information);
}

.content-btns-accions {
    display: flex;
    width: 100% !important;
    justify-content: flex-end;
}

.content-btn-primary {
    width: 200px;
}

.content-btn-second {
    width: 200px;
    margin-right: 15px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
    .demoColorResponsive {
        background-color: rgb(190, 136, 226);
    }

    .content-btns-accions {
        display: block;
        width: 100% !important;
    }

    .content-btn-second {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 15px;
    }

    .content-btn-primary {
        width: 100%;
    }

    .text-img-add {
        color: var(--primary-color-text) !important;
        font-family: "pop-Semibold";
        font-size: 6px;
        text-transform: initial;
    }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
        background-color: rgb(136, 226, 151);
    }

    .text-img-add {
        color: var(--primary-color-text) !important;
        font-family: "pop-Semibold";
        font-size: 6px;
        text-transform: initial;
    }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
        background-color: rgb(201, 122, 83);
    }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(204, 198, 109);
    }
}

/* XL */
@media (min-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(113, 199, 201);
    }
}
</style>